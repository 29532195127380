.terms-section {
  margin: 0 auto;
  padding: 120px 40px 40px;
  max-width: 1440px;
  width: 100%;
}

.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.terms-overlay {
  margin-top: 2%;
  padding: 10px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}

.terms-content {
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  font-family: "Roboto", sans-serif;
  display: block;
  gap: 1rem;
  padding: 40px;
  margin: 0 auto;
}

.terms-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .terms-section {
    padding: 100px 20px 20px;
  }

  .terms-content {
    padding: 20px;
  }

  .terms-overlay {
    font-size: 20px;
  }

  .terms-title {
    font-size: 20px;
    padding: 20px;
    margin-bottom: 0;
  }

  .terms-content {
    font-size: 14px;
  }
}
