/* FeaturedSlider Component */
.featured_swiper {
position: relative;
padding: 30px 0;
z-index: 1;
cursor: pointer;

}

.featured_slides {
position: relative;
overflow: hidden;
display: flex;
flex-direction: row;
/* width: calc((100% - 100px) / 3);
height: 370px; */
background-color: #f5f5f5 !important;
border-radius: 10px;
transition: all 0.3s ease;
border: 1px solid transparent;
margin:12px
  
}

.featured_slides:hover {
transform: scale(1.02);
z-index: 2;
}

.featured_slides.swiper-slide-active, .featured_slides:hover {
transform: scale(1.05);
z-index: 2;
/* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); */
border: 1px solid rgba(255, 255, 255, 0.564);
}
 .swiper-pagination-bullet-active{
background-color: black;
}
.featured_title {
font-family: "Archivo", sans-serif;
font-size: 16px;
font-weight: 400;
color: #000;
margin-top: 30px;
margin-bottom: 20px;
text-align: center;
}


.featured_img {
width: 100%;
height: 220px;
margin: 0 auto;
background-color: transparent;
display: flex;
justify-content: center;
align-items: center;
}

.featured_img img {
width: 100%;
height: 100%;
object-fit: contain;
background-color: transparent;
transition: all 0.3s ease;
}

.products_price {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 30px;
}

.final_price {
font-family: "Archivo", sans-serif;
font-size: 26px;
font-weight: 900;
color: #000;
margin-right: 5px;
}

.old_price {
font-family: "Archivo", sans-serif;
font-size: 18px;
font-weight: 600;
color: rgb(75, 66, 66);
text-decoration: line-through;
margin-left: 5px;
}
.featured_slides:hover .featured_title,
.featured_slides:hover .final_price,
.featured_slides:hover .old_price {
color: #0E46A3;
}

@media screen and (max-width: 1199px) {
.featured_slides {
width: calc((100% - 50px) / 2);
margin-right: 50px;
}
}

@media screen and (max-width: 992px) {
.featured_slides {
width: calc((100% - 30px) / 2);
margin-right: 20px;
}
}

@media screen and (max-width: 767px) {
.featured_slides {
   width: 45vw;
    margin-right: 1px;
    height: auto;

}
}

@media screen and (max-width: 575px) {
.featured_swiper {
  padding: 55px 0;
  z-index: 1;
}}

.featured_slides {
 width: 50vw;
  height: fit-content;
  margin-right: 1px;
}
.featured_slides:hover {
  transform: scale(1.1);
  z-index: 2;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
  /* border: 2px solid transparent; */
}

.products_price {
   display: flex;
  justify-content: center;
  align-items: center;
margin-bottom: 20px;
}



/* FeatureSlider.css */
.featured_swiper {
  width: 100%;
  height: 100%;
}

.featured_slides {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.featured_title {
  font-size: 1.2rem;
  font-weight: bold;
}

.featured_img {
  width: 200px;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.products_price {
  font-size: 1rem;
  color: #333;
}

.final_price {
  color: #ff5722;
}

.old_price {
  color: #999;
}

.swiper-no-swiping {
  pointer-events: none;
}
