.quantity-control-wrapper {
  display: flex;
  align-items: center;
}

.add-item-decrease {
  color: #f9f9f9 !important;
  background-color: black !important;
  padding: 0 !important;
}

.add-item-decrease:hover {
  color: #f44336;
}

.add-item-decrease-icon {
  padding: 5px;
}

.add-item-increase {
  color: #f9f9f9 !important;
  background-color: black !important;
  padding: 0 !important;
}

.add-item-increase:hover {
  color: #00aaa4;
}

.add-item-increase:disabled {
  color: #f9f9f9 !important;
  background-color: #8a8a8a !important;
}

.item-quantity {
  color: black !important;
  font-weight: 500;
}
