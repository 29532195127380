.shop-main {
  position: relative;
}

.productPage {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #f1f1f1;
  gap: 20px;
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 20px 40px;
  margin: 5rem auto 0;
}
.filterAccordion {
  width: 100%;
  margin-top: 40px;
}

.category-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  color: black;
  font-size: 16px;
  padding: 4px;
}

.category-wrapper > h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.category-checkbox-wrapper {
  margin: 0 !important;
}

.category-checkbox {
  color: black !important;
  margin-right: 4px !important;
}

.productPageTop {
  display: flex;
  width: 100%;
  gap: 1rem;
  max-width: 300px;
  margin-bottom: 1rem;
  height: 100%;
  position: sticky;
  top: 100px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  width: 80%;
}

.homeCardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Two cards per row */
  gap: 14px; /* Space between the cards */
  justify-items: center; /* Center cards horizontally */
  width: 100%;
  grid-auto-rows: minmax(min-content, max-content);
}

@media (max-width: 1424px) {
  .homeCardContainer {
    grid-template-columns: repeat(
      4,
      1fr
    ); /* Still two cards per row on mobile */
  }
}

@media (max-width: 1200px) {
  .homeCardContainer {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Still two cards per row on mobile */
  }
}

@media (max-width: 1050px) {
  .productPage {
    flex-direction: column;
    align-items: center;
  }

  .productPageTop {
    position: initial;
    max-width: initial;
  }
  .homeCardContainer {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Still two cards per row on mobile */
  }
}

@media (max-width: 900px) {
  .homeCardContainer {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Still two cards per row on mobile */
  }
}

@media (max-width: 480px) {
  .homeCardContainer {
    width: 100%;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Switch to one card per row on very small screens */
  }
}

.filterBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 20%;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.329);
  min-width: 300px;
}

.priceFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.priceSlider {
  width: 90%;
}
.priceSlider .css-187mznn-MuiSlider-root {
  height: 3px !important;
  color: #414141 !important;
}

.price-slider-component {
  color: #65a79b !important;
}

.priceSelector {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 90%;
  margin: 10px 0;
  color: #f9f9f9;
}

.priceOption {
  width: 90%;
  background-color: #fdfdfd !important;
  text-align: center;
  color: #d6d6d6 !important;
}

.toText {
  font-size: 16px;
  font-weight: 400;
  color: black;
}
.filter_divider {
  width: 100%;
  height: 0.8px;
  margin: 1.5rem 0;
  background-color: black;
}
.menu_item {
  color: white !important;
  box-shadow: rgb(255, 255, 255) !important;
}

.categoriesFilter {
  width: 90%;
}
.categoryBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
}
.category-link {
  display: flex;
  align-items: center;
  list-style: none;
  color: rgba(0, 0, 0, 0.61);
  font: 400 0.8vmax "Asul";
  margin: 0.4vmax;
  cursor: pointer;
}
.category-label {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-checkbox {
  width: 16px;
  height: 16px;
  color: #170f05;
}

.category-checkbox svg {
  width: 30px !important;
  height: auto !important;
}

.category-checkbox:checked + .category-label::before {
  background-color: #414141;
}

.ratingsFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ratingsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}
.paginationBox {
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
}

.paginationBox > ul > li {
  padding: 10px 15px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 2rem 20px;
  flex-wrap: wrap;
}
.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  transition: all 0.3s;
  cursor: pointer;
  text-decoration: none !important;
}

.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}
.page-link {
  text-decoration: none !important;
  font: 300 0.9vmax "Asul";
  color: #020202 !important;
  transition: all 0.3s;
}

.page-link:hover,
.page-item:hover {
  background-color: #65a79b;
  color: white;
}

.pageItemActive {
  background-color: #65a79b;
  font-weight: bolder;
  color: white;
}

.pageLinkActive {
  color: white;
}

.css-187mznn-MuiSlider-root {
  color: #170f05 !important;

  height: 1.2px !important;
}
.css-eg0mwd-MuiSlider-thumb {
  width: 10px !important;
  height: 10px !important;
}

.css-ahj2mt-MuiTypography-root {
  text-align: center;
}

.categoryBox {
  padding: 0%;
}

.css-1t2bqnt {
  color: #170f05 !important;
}

.css-exkjcx {
  color: #414141 !important;
}

.filterBox > fieldset {
  border: 1px solid rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 1050px) {
  .filterBox {
    width: 100%;
  }
  .page-link {
    font: 300 1.2vmax "Asul";
  }
  .products1 {
    width: 55vw;
  }
  .category-link {
    font: 400 1.3vmax "Asul";
  }
}

@media screen and (max-width: 800px) {
  .products1 {
    width: 45vw;
  }

  .page-link {
    font: 300 1.5vmax "Asul";
  }
  .category-link {
    font: 400 1.6vmax "Asul";
  }
}

@media screen and (max-width: 768px) {
  .productPage {
    padding: 0 20px;
  }

  .filterBox {
    padding: 0 32px 32px;
  }
}

@media screen and (max-width: 600px) {
  .productPageTop {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
  .filterBox {
    width: 100%;
    margin: 1rem 0 0 0;
  }

  .products {
    width: 100%;
    margin: 1rem 0 0 0;
  }
}
