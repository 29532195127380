.home-main {
  position: relative;
}

.trending_heading {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.trending-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.8rem;
  margin: 0 1.8rem;
  padding-bottom: 10%;
}

.newArrivals_Grid {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  justify-content: center;
  gap: 14px;
}

.banner-grid-wrapper {
  padding: 0 40px !important;
  margin: 40px auto !important;
  max-width: 1440px;
}

.slider-card-wrapper {
  padding: 0 7px !important;
}

@media only screen and (min-width: 600px) {
  .trending-products {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 960px) {
  .trending-products {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .trending-products {
    max-width: 1440px;
    margin: 0 auto;
  }
  .newArrivals_Grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .newArrivals_Grid {
    padding: 0 20px;
    grid-template-columns: repeat(
      2,
      minmax(0, 1fr)
    ); /* 2 items per row for small screens */
  }

  .banner-grid-wrapper {
    padding: 0 20px !important;
    margin: 20px auto !important;
  }
}

@media screen and (max-width: 480px) {
  .home-card-image {
    min-height: 180px !important;
  }
}
