.video-container {
    position: relative;
    width: auto;
    max-height: 50vh !important;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    margin: 40px 0;
  }
  
  .video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  