@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  background-color: #f1f1f1;
}

.container {
  perspective: 1000px;
}

.card {
  width: 300px;
  height: 400px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}

.sneaker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sneaker img {
  width: 250px;
  transition: all 0.5s ease;
}

.info {
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 500;
  transition: all 0.5s ease;
}

.sizes {
  margin-top: 10px;
}

.sizes button {
  border: none;
  background-color: #f1f1f1;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.sizes button.active {
  background-color: #e74c3c;
  color: white;
}

.purchase button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #e74c3c;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.purchase button:hover {
  background-color: #d62c1a;
}


/* Here is the code for the CardsAnimated component. */
body {
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 200px;
  text-align: center;
  position: relative;
}

.product-image {
  background-color: #4a625f; /* Background color behind the image */
  padding: 10px;
}

.product-image img {
  width: 100%;
  height: auto;
}

.product-info {
  padding: 10px;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.product-price {
  font-size: 14px;
  color: #888;
}

.add-to-cart {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.add-to-cart-btn {
  background-color: #ffba00;
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-to-cart-btn:hover {
  background-color: #e0a800;
}
