.container__0 {
  max-width: 900px;
  margin: 5rem auto 0;
  padding: 20px;
  background-color: white;
}

.image-container {
  position: relative;
  margin-bottom: 20px;
}

.image-container img {
  width: 100%;
  height: auto;
}

.policy-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content-container {
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  width: 90%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  display: block;
margin: 0 auto;
  gap: 1rem;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

.contact-info {
  font-weight: bold;
  margin-bottom: 10px;
}

.email {
  color: #007bff;
  text-decoration: none;
  font-weight: 700;
}

.email:hover {
  text-decoration: underline;
}

.phone {
  color: #333333;
}
