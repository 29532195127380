/* SliderComponent.css */
.trim-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.slider-container a {
  text-decoration: none !important;
  color: #000 !important;
}

.slider-container {
  width: 100%;
  margin: 20px 0;
}

.slider-item {
  text-align: center !important;
  padding: 10px;

  cursor: pointer;
  border: 0 !important;
}
h1 {
  text-align: center !important;
  margin-top: 20px;
}

.slider-item img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
