.cart-table-row {
  display: flex;
  padding: 20px 0;
  color: black;
  border-bottom: 1px solid #00000063;
}

.cart-table-row:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.card-table-item-col {
  width: 50%;
}

.card-table-price-col {
  width: 16.6%;
  display: flex;
  flex-direction: column;
}

.card-table-price-col-old-price {
  text-decoration: line-through;
}

.card-table-quantity-col {
  width: 16.6%;
}

.card-table-total-col {
  width: 16.6%;
  text-align: right;
}

.card-table-total-col-total {
  display: none;
}

@media screen and (max-width: 768px) {
  .cart-table-row {
    border-top: 1px solid #00000063;
    border-bottom: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100% !important;
    row-gap: 16px;
  }

  .card-table-item-col {
    grid-column: span 2;
    width: 100% !important;
    border-bottom: 1px solid #00000063;
    padding-bottom: 20px;
  }

  .card-table-price-col {
    grid-column: span 1;
    width: 100% !important;
    border-bottom: 1px solid #00000063;
    padding-bottom: 20px;
  }

  .card-table-quantity-col {
    grid-column: span 1;
    width: 100% !important;
    border-bottom: 1px solid #00000063;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .card-table-total-col {
    grid-column: span 2;
    width: 100% !important;
    text-align: left !important;
    display: flex;
  }

  .card-table-total-col-total {
    display: block;
    width: 100% !important;
  }

  .card-table-total-col-price {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
  }
}
