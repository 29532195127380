* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  max-width: 100vw;
}

#root {
  margin-top: 45px;
}

body {
  background-color: #f1f1f1;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}

a {
  color: inherit;
  text-decoration: none;
}

#__react-alert__ > div > div > div {
  width: 450px !important;
}

@media screen and (max-width: 768px) {
  #__react-alert__ > div > div > div {
    width: 300px !important;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #121212;
  /* border: none !important; */
}
