.cart-table {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding: 30px 0;
}

.cart-table-header {
  display: flex;
  color: #fff;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  padding: 1rem 0;
  color: black;
  width: 100%;
  font-size: 22px;
}

.card-table-item-col {
  width: 50%;
}

.card-table-price-col {
  width: 16.6%;
}

.card-table-quantity-col {
  width: 16.6%;
}

.card-table-total-col {
  width: 16.6%;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .cart-table-header {
    display: none;
  }
}
