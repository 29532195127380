.rootProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 80px auto 0;
  padding: 40px;
  gap: 40px;
}

.profileDetials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileDetials > a {
  width: 100% !important;
}

.righHeadings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileDetials > a > button {
  width: 100% !important;
  margin: 0 !important;
  padding: 12px !important;
  max-width: 100% !important;
}

.header-root {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 40px 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.headingProfile {
  text-align: center;
  font-weight: 900 !important;
  font-size: 30px !important;
  text-transform: uppercase;
  color: #414141;
}

.profileConatiner {
  display: flex;
  width: 100%;
  gap: 12px;
}

.leftCotainer {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 18rem;
  padding: 0;
}

.greeting {
  font-size: 16px;
  color: #414141;
  font-weight: 500 !important;
}

.profileHeadingLeft {
  font-size: 20px !important;
  font-weight: 800 !important;
  margin-bottom: 1rem !important;
  color: #212121 !important;
  background-color: inherit !important;
  text-transform: uppercase !important;
}

.profileHeading {
  font-size: 18px !important;
  font-weight: 900 !important;
  color: #212121 !important;
  text-transform: uppercase !important;
  text-transform: left !important;
}

.profileSection {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  min-width: 300px;
}

.profileAvatar {
  width: 90px !important;
  height: 90px !important;
  margin-bottom: 8px !important;
  font-size: 2rem !important;
  font-weight: 800 !important;
  background-color: #414141 !important;
}

.leftDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.profileSubHeading {
  font-size: 1rem !important;
  color: black !important;
  font-weight: bold !important;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.profileText {
  font-size: 1rem !important;
  color: #414141;
  text-transform: uppercase;
}

.profileText2 {
  font-size: 16px;
  color: #414141;
  font-weight: 500 !important;
}

.profileText3 {
  font-size: 14px;
  color: black;
  font-weight: 400;
  /* width: 80%; */
}

.myOrder {
  width: 100%;
  background-color: white !important;
  text-align: center;
  padding: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px !important;
}

.myOrder > h1 {
  margin-bottom: 16px;
}

.ordersButton {
  width: 100%;
  padding: 0.5rem !important;
  margin-bottom: 8px !important;
  background-color: #414141 !important;
  color: #fff !important;
}
.profileButton {
  width: 50% !important;
  padding: 0.5rem !important;
  margin: 1rem !important;
  background-color: #414141 !important;
  color: #fff !important;
}
.ordersButton:hover {
  background-color: #9caccc !important;
}

.profileButton:hover {
  background-color: #9caccc !important;
}

.righHeadings {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.rightConatiner {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  height: 100%;
}

.detials {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
  gap: 10px;
}

.mangeAccount {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media screen and (max-width: 768px) {
  .rootProfile {
    padding: 20px;
    gap: 12px;
  }

  .profileConatiner {
    flex-direction: column;
    gap: 12px;
  }

  .leftCotainer {
    margin-bottom: 0;
    max-width: 100%;
  }

  .headingProfile {
    font-size: 24px !important;
  }
}
