.product-container {
  display: flex !important;
  flex-direction: row !important;
  padding: 40px !important;
  margin-top: 6.5rem !important;
  justify-content: space-between !important;
  background-color: #f1f1f1;
  width: 100%;
  max-width: 1440px !important;
  margin: 0 auto;
  align-items: start;
  gap: 20px;
}

.image-gallery {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 20px !important;
  gap: 10px !important;
  width: 100%;
  min-width: 500px;
}

.payment-options {
  display: flex !important;
  margin-bottom: 5px !important;
  align-items: center !important;
  justify-content: center !important;
}

.thumbnails {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important; /* 4 elements per row */
  gap: 10px !important;
  min-width: 60px !important;
  max-height: 260px !important;
}

.thumbnails img {
  width: 100% !important;
  height: 90px !important;
  cursor: pointer !important;
  object-fit: cover !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.main-image {
  position: relative;
  height: 350px !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.main-image img {
  position: absolute;
  width: 100%;
  height: 100% !important;
  object-fit: cover !important;
}

.product-details {
  display: flex !important;
  flex-direction: column !important;
  background-color: #f1f1f1 !important;
  color: #000 !important;
  padding: 0 20px !important;
  border-radius: 4px !important;
}

.product-details h1 {
  font-size: 24px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

.product-details p {
  margin: 5px 0 !important;
}

.price-wrapper {
  display: flex !important;
  gap: 16px;
  align-items: baseline;
}

.price {
  font-size: 35px !important;
  margin-bottom: 10px !important;
}

.strike-price {
  font-size: 20px !important;
  color: #aaa !important;
  text-decoration: line-through !important;
}

.cta-button {
  background-color: #121212 !important;
  border: none !important;
  padding: 10px !important;
  cursor: not-allowed !important;
  margin-bottom: 10px !important;
}

.product-description p {
  margin-bottom: 10px !important;
}

.size-chart {
  margin-bottom: 20px !important;
}

.size-chart h3 {
  margin-bottom: 10px !important;
}

.sizes {
  margin-top: 12px;
  display: flex !important; /* Switch to grid layout */
  flex-wrap: wrap !important; /* 6 equal columns */
  width: 100% !important;
  gap: 10px !important; /* Space between items */
}

.size-button {
  max-width: 64px;
  box-sizing: border-box !important; /* Ensures padding is included in width */
  color: #000 !important;
  background-color: #fff !important;
  padding: 10px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  border-radius: 3px !important;
  text-align: center !important; /* Centers text inside */
  border: 1px solid #c1c1c1 !important;
}

.size-button:disabled {
  color: #aaa !important; /* Grayed out text */
  background-color: #f5f5f5 !important; /* Light gray background */
  cursor: not-allowed !important; /* Disabled cursor */
  border: 1px solid #aaa !important;
  opacity: 0.6; /* Make it slightly faded */
}

.MuiSelect-select {
  color: #000 !important;
  background-color: #fff !important;
  padding: 4px 30px !important;
}

.dropdown-button {
  color: #fff !important;
}

.MuiList-root {
  max-height: 200px !important;
  overflow-y: scroll !important;
  scroll-behavior: smooth !important;
  scroll-snap-type: y mandatory !important;
}

.size-button-selected {
  max-width: 64px !important;
  background-color: black !important;
  color: #fff !important;
  border: 2px solid #000 !important;
}

.size-button:hover {
  color: #121212 !important;
}

.product-buttons {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}

.product-buttons .add-to-cart,
.product-buttons .add-to-wishlist {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
}

.product-buttons .add-to-wishlist {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.shipping-info {
  margin-bottom: 20px !important;
}

.shipping-info p {
  margin: 5px 0 !important;
}

.shipping-info a {
  font-weight: bold !important;
  text-decoration: none !important;
  max-width: 200px;
}

.view-details {
  color: #00918c !important;
  text-decoration: none !important;
}

.prod_details_addtocart_btn {
  cursor: pointer !important;
  line-height: inherit !important;
  padding: 0px 16px !important;
  position: relative !important;
  text-decoration: none !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: inherit !important;
  min-height: 48px !important;
  background: #000000 !important;
  color: rgb(255, 255, 255) !important;
  transition: transform 0.3s ease-in-out 0s !important;
  margin-top: 20px;
  box-shadow: none !important;
  border: none !important;
}

.see_more_btn {
  cursor: pointer !important;
  line-height: inherit !important;
  padding: 0px 16px !important;
  position: relative !important;
  text-decoration: none !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: inherit !important;
  min-height: 48px !important;
  background-color: white !important;
  transition: transform 0.3s ease-in-out 0s !important;
  color: black !important;
  border: 2px solid black !important;
}

.see_more_btn:disabled {
  background-color: #ccc !important;
  color: #888 !important;
  cursor: not-allowed !important;
}

.see_more_btn:hover {
  color: white !important;
  background: black !important;
  transform: scale(1.05) !important;
  border: none !important;
  transition: transform 0.3s ease-in-out 0s !important;
}

.prod_details_addtocart_btn:disabled {
  background-color: #ccc !important;
  color: #888 !important;
  cursor: not-allowed !important;
}

.prod_details_addtocart_btn:hover {
  color: white !important;
  background: black !important;
  transform: scale(1.05) !important;
  border: none !important;
  transition: transform 0.3s ease 0s !important;
}

button {
  cursor: pointer !important;
}

.prod_details_price .tax_txt {
  color: #403e3e !important;
  font-size: 0.9rem !important;
}

.instock {
  font-weight: 400 !important;
  background-color: #4ed54e40 !important;
  color: #02a102 !important;
  font-size: 12px !important;
  padding: 5px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
  align-items: center !important;
  display: flex !important;
  width: fit-content !important;

  gap: 10px !important;
}

.outofstock {
  background-color: #ff7a7a !important;
  color: white !important;
  font-size: 14px !important;
  padding: 5px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  display: flex !important;
  width: 100% !important;
  max-width: 130px !important;
  gap: 10px !important;
  align-items: center !important;
}

.info-section {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100%;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust gap as needed */
  margin: 12px 0;
  max-width: 200px;
}

.button-container > button {
  border: 2px solid #fff !important;
}

.size-button,
.size-button-selected {
  flex: 1 1 calc(50% - 10px); /* Two buttons per row with a gap */
  box-sizing: border-box;
  padding: 10px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.size-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
}

.size-button-selected {
  background-color: #414141;
  color: #fff;
  border: 1px solid #333;
}

@media screen and (max-width: 1024px) {
  .product-container {
    flex-direction: column !important;
  }

  .image-gallery {
    flex-direction: column !important;
    min-width: auto;
  }

  .main-image {
    position: relative;
    height: 450px !important;
  }

  .thumbnails {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

@media screen and (max-width: 768px) {
  .product-container {
    padding: 40px 20px !important;
  }

  .product-details {
    padding: 0 !important;
  }

  .main-image {
    position: relative;
    height: 400px !important;
  }

  .thumbnails {
    grid-template-columns: repeat(4, 1fr) !important; /* 4 elements per row */
  }

  .button-container {
    max-width: none;
  }

  .shipping-info a {
    max-width: none;
  }
}

@media screen and (max-width: 560px) {
  .main-image {
    position: relative;
    height: 260px !important;
  }

  .thumbnails {
    grid-template-columns: repeat(4, 1fr) !important; /* 4 elements per row */
  }
  .thumbnails img {
    height: 60px !important;
  }

  .sizes {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
  }

  .size-button,
  .size-button-selected {
    max-width: none !important;
    min-width: auto !important;
    width: 100%;
    grid-column: span 1;
  }
}

@media screen and (max-width: 420px) {
  .sizes {
    grid-template-columns: repeat(5, 1fr);
  }
}
