.search-bar {
  margin: 15px 0;
}

.search_from {
  width: 61vw;
  padding: 5px;
}

.search_from input {
  width: 100%;

  margin: 0 !important;
}

@media (max-width: 899px) {
  .search_from {
    width: 40vw;
  }
}

.closeIcon {
  color: #000000;
  font-size: 1.8rem;
  transition: color 0.2s ease-in-out;
  /* margin-right: 10px; */
}

/* Apply the hover effect on the CloseOutlined icon */
.closeIcon:hover {
  color: #0e46a3;
}
