.cartPage {
  overflow: hidden;
  /* min-width: 100% !important; */

  padding: 0rem 40px 40px;
  width: 100%;
  max-width: 1440px;
  margin: 10rem auto 0;
}

.cart_HeaderTop {
  display: flex;
  align-items: end;
  gap: 5rem;
  color: black !important;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: fit-content;
}

.cartHeading {
  padding-left: 2px;
  font-size: 1.8rem !important;
  font-weight: 900 !important;
  color: black !important;
}

.cartText3 {
  font-size: 14px !important;
  color: black !important;
  font-weight: 500 !important;
}

.cartText2 {
  font-size: 18px !important;
  color: #525252 !important;
  font-weight: 500 !important;
  text-decoration: underline;

  cursor: pointer;
}
.cartText2:hover {
  color: #00aaa4 !important;
}

.emptyCartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 60vh; */
  text-align: center;
  gap: 1rem;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 1rem;
  height: 420px;
}

.emptyCartContainer .cartIcon {
  font-size: 5rem;
  color: rgb(124, 123, 123);
}

.emptyCartContainer .cartIcon {
  color: rgb(124, 123, 123);
}

.emptyCartContainer .cartHeading {
  font-family: "Asul", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  font-size: 1.3rem !important;
  font-weight: 900 !important;
  color: rgb(124, 123, 123) !important;
  line-height: 35px;
}

.emptyCartContainer .cartText {
  font-family: "Asul", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  font-size: 14px !important;
  color: rgb(124, 123, 123) !important;
  font-weight: 400 !important;
}

.emptyCartContainer .shopNowButton {
  background-color: rgb(40, 38, 38);
  color: white;
  min-height: 48px;
  width: 100%;
  padding: 4px 16px;
}

.emptyCartContainer .shopNowButton:hover {
  background-color: #00aaa4;
}

.cart_content_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cart_left_container {
  width: 60%;
}

.cart_item {
  width: 100%; /* Ensure each cart item takes the full width */
  max-width: 500px; /* Set a max width for better readability */
  margin: 10px 0; /* Add some margin between items */
  text-align: center; /* Center the content of the cart item */
}

/* Set the scrollbar width and background color */
::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

/* Set the scrollbar thumb color and border radius */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#414141, #3c3c3c);
  border-radius: 10px;
}

/* Set the scrollbar track color */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.separator_cart2 {
  width: 43%;
  height: 1px;
  background: rgba(70, 70, 70, 0.3);
  margin: 16px 0;
}

/* ??????????????????? */
.cart_right_container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 1rem 0;
}

.order_summary {
  display: flex;
  flex-direction: column;
  color: black;
  width: 100%;
}

.order_summary .order_Summary_Item > span,
.order_summary .order_Summary_Item > div > p {
  color: black !important;
}

.order_summary h5 {
  font-size: 1rem;
  font-weight: 600;
}
.separator_cart {
  width: 100%;
  height: 1px;
  background: rgba(174, 171, 171, 0.593);
  margin: 10px 0;
}
.order_Summary_Item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.order_Summary_Item span {
  font-size: 16px;
  font-weight: 600;
  color: #414141;
}
.order_Summary_Item p {
  font-size: 16px;
  font-weight: 500;
}
.added-label {
  opacity: 0.5;
}
.order_Summary_Item h4 {
  font-size: 16px;
  font-weight: 800;
}
.coupon-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.coupon-box-content {
  /* Existing rules */
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Add this rule */
  align-items: center;
  background-color: transparent;
  border: 1px solid #414141;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease-in-out;
  width: 100%;
}

.coupon-box-content.focused {
  border-color: black;
  width: 100%;
}

.coupon-box-content .MuiFormControl-root {
  width: 100%;
}

.coupon-box-content .MuiInputLabel-outlined.Mui-focused {
  transform: translate(14px, -6px) scale(0.75);
}

.coupon-box-content .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.coupon-box-content
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

.coupon-box-content .MuiOutlinedInput-input {
  padding: 8px;
  font-size: 1rem;
}

.coupon-box-content .MuiButton-containedPrimary {
  background-color: rgb(40, 38, 38);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px 16px;
  text-transform: none;
  margin-left: 1rem;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.coupon-box-content .MuiButton-containedPrimary:hover {
  background-color: #00aaa4;
}

.coupon-box-content .MuiFormHelperText-root {
  color: #00aaa4;
  font-size: 0.75rem;
}
.coupon-box-content .MuiInputLabel-outlined.Mui-focused {
  color: black;
}
.coupon-box-content .MuiButton-containedPrimary {
  /* Existing rules */
  width: 30% !important;
}
.coupon-box-content .MuiFormControl-root {
  /* Existing rules */
  width: 70% !important;
  height: 80%;
}
.btn-custom {
  background-color: black !important;
  color: #fff !important;
  display: block !important;
  width: 100% !important;
  margin-top: 1rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 35px !important;
  min-height: 48px !important;
  padding: 4px 16px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  transition: all 0.15s ease-out !important;
  cursor: pointer !important;
  white-space: nowrap !important;
}

.btn-custom:hover {
  background-color: #00aaa4 !important;
}

.paymentLogoImg {
  margin-top: 1.5rem;
  width: 97%;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
}
.paymentImg {
  width: 100%;
  height: 100%;
}

.delivery-type-checkbox svg {
  color: black;
}

.cartText2 {
  font-size: 14px;
}
.cartHeading {
  font-size: 16px;
}

.additem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.additem_decrease {
  margin-right: 8px;
  height: 24px;
  font-size: 14px;
  background-color: none;
  width: 0px;
  border-color: none;
  border-radius: 3px;
  color: black;
  margin-left: 5px;
}

.additem_increase {
  margin-left: 10px;
  height: 24px;
  background-color: none;
  width: 0px;
  border-color: none;
  border-radius: 3px;
  color: black;
}

.additem_increase:hover {
  color: #00aaa4;
  transition: transform 0.3s ease 0s;
  border-style: 2px solid;
}

.additem_decrease:hover {
  color: #00aaa4;
  transition: transform 0.3s ease 0s;
  border-style: 2px solid;
}

input[type="number"] {
  width: 32px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@media (max-width: 1024px) {
  .cart_HeaderTop {
    justify-content: space-between;
    gap: 0;
  }

  .cartText2 {
    font-size: 14px !important;
    text-align: right;
  }
  .cart_content_wrapper {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .cart_left_container {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .separator_cart2 {
    width: 95%;
  }
  .separator_cart3 {
    width: 100%;
    height: 1px;
    background: #cacaca61;
  }
  .cart_right_container {
    align-self: center;
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .cartPage {
    margin-top: 8rem;
    padding: 20px;
  }

  .emptyCartContainer {
    height: auto;
  }
}

/* cartItem css  */

.prod_details_additem {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 499px) {
  .prod_details_additem h5 {
    font-size: 12px;
  }
  .prod_details_additem {
    margin-top: 2rem;
    flex-direction: column;
    gap: 1rem;
  }
  .makeStyles-cartSubHeadings-13 {
    font-size: 12px !important;
  }
  del {
    font-size: 12px !important;
  }
}
