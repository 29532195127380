.cricket-ball-loader {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.spinner {
  width: auto;
  height: 7.5rem;
  animation: spin 1s infinite linear;
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  filter: invert(1);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
