.cart-wrapper {
  display: flex;
}

.card-media {
  width: 125px;
  height: 90px;
}

.card-content {
  padding: 0 20px !important;
}

.card-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
