.slider-container-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
}

@media screen and (max-width: 768px) {
  .slider-container-wrapper {
    padding: 0 20px;
  }
}
