.footer {
  width: 100%;
  background-color: black;
}

.sub_footer_root {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
}

.sub_footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
}

.foot_policyLink {
  width: 100%;
}

.foot_policyLink ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  color: white;
  padding: 0;
  width: 100%;
  margin: 0;
}

.foot_policyLink li > a {
  color: white;
  text-decoration: none;
  width: fit-content;
  transition: all 0, 3s ease;
}

.foot_policyLink li > a:hover {
  color: rgb(167, 167, 167);
}

.foot_copyright p {
  color: white;
  font-size: 14px;
  margin-bottom: 0;
  text-decoration: none;
}

.footer_copyright_link {
  text-decoration: none;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .sub_footer_root {
    padding: 0 20px;
  }

  .sub_footer_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .foot_policyLink ul {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
}
