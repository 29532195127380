.carousel-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 40px auto 0;
  padding: 0 40px;
}

.slide {
  height: 600px;
  width: 100% !important;
  position: relative;
  overflow: hidden;
}

.slide-content {
  position: absolute;
  top: 65%;
  left: 10%;
  transform: translateY(-50%);
  text-align: left;
  color: #fff;
  z-index: 2;
  padding-right: 20px;
}

.quote {
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 8px;
}

.sale-text {
  font-size: 32px;
  font-family: "Asul", sans-serif;
  font-weight: 800;
  width: 100%;
  margin-bottom: 16px;
}

.product-button {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 4px !important;
  padding: 8px 24px !important;
  margin-top: 16px !important;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out !important;
}

.product-button:hover {
  background-color: #fff !important;
  color: #000 !important;
  transform: scale(1.1) !important;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

@media (max-width: 768px) {
  .carousel-wrapper {
    padding: 0 20px;
  }

  .slide {
    height: 450px;
  }

  .slide-content {
    left: 10%;
  }

  .quote {
    font-size: 14px;
  }

  .sale-text {
    font-size: 24px;
  }

  .product-button {
    font-size: 16px !important;
    padding: 4px 16px !important;
  }
}

@media (max-width: 480px) {
  .slide-content {
    left: 5%;
  }

  .quote {
    font-size: 12px;
  }

  .sale-text {
    font-size: 20px;
  }

  .product-button {
    font-size: 14px !important;
    padding: 4px 12px !important;
  }
}
