.shippingPage {
  padding: 2rem 0rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

select {
  border: none;
  width: "-webkit-fill-available";
  font-size: 16px;
  padding: 1rem;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  select {
    width: "-webkit-fill-available";
    font-size: 16px;
    justify-content: space-around;
    padding: 0rem;
  }
}
