.slider-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998 !important; /* High z-index to be on top of all other components */
}

.slider {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  max-width: 80%;
  max-height: 80%;
  z-index: 9999 !important;
}

.slider__image-container {
  width: 500px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  height: 326px;
}

.slider__image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 600px;
  display: block;
  border-radius: 5px;
  transition: transform 0.3s ease; /* Smooth zoom transition */
}

.slider__image.zoomed {
  transform: scale(2); /* Double the size of the image */
}

.slider__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px 0;
}

.slider__nav-btn {
  border: none;
  border-radius: 50%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background: transparent;
  cursor: pointer;
}

.slider__thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  overflow-x: auto;
}

.slider__thumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0.6;
}

.slider__thumbnail.active {
  border: 2px solid #000;
  opacity: 1;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .slider__image-container {
    width: 400px;
    height: 256px;
  }
}

@media screen and (max-width: 500px) {
  .slider__image-container {
    width: 330px;
    height: 200px;
  }
}
