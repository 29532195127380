.privacy-policy-section {
  margin: 0 auto;
  padding: 120px 40px 40px;
  max-width: 1440px;
  width: 100%;
}

.privacy-policy-container {
  width: 100%;
  background-color: #ffffff;
  padding: 40px;
}
.container___ {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container___ h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.container___ h2 {
  margin: 0 0 10px !important;
  font-size: 20px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.container___ ul {
  margin-left: 15px;
}

.container___ li {
  margin: 10px;
  list-style: disc;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy-section {
    padding: 100px 20px 20px;
  }

  .privacy-policy-container {
    padding: 20px;
  }

  .container {
    padding: 10px;
  }

  .container___ h1 {
    font-size: 20px;
  }

  .container___ h2 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .container___ p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
